import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { PageLayout } from "../layouts";
import { Block, DropDown, TextField, TextArea, TrackedLink } from '../components';

import "../components/forms/forms.scss";
import "../global/styles/page-styles/form-page.scss";

const API_ENDPOINT_CONTACT = process.env.API_ENDPOINT_CONTACT;

/**
 * Options to display in select.
 */
const INQUIRY_OPTS = [
  { value: "General Inquiry", label: "General Inquiry" },
  { value: "Technical Support", label: "Technical Support" },
  { value: "Partner Support", label: "Partner Support" },
  { value: "Publisher Inquiry", label: "Publisher Inquiry" },
  { value: "Press Inquiry", label: "Press Inquiry" },
  { value: "Trust & Safety", label: "Trust & Safety" },
  { value: "Billing", label: "Billing" },
  { value: "Something Else", label: "Something Else" },
];

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Please enter a valid email address'),
  comments: Yup.string().min(1, 'Too Short!').required('Required')
});


const ContactPage = () => {
  const [formError, setFormError] = useState(undefined);
  const [successData, setSuccessData] = useState(undefined);

  /**
   * Store initial form state.
   */
  const initialValues = {
    subject: "",
    name: "",
    email: "",
    comments: "",
  }

  const handleSubmit = (values) => {
    axios.post(API_ENDPOINT_CONTACT, values)
      .then((res) => {
        if (res.data.success === true) {
          // Update UI to show Success MSG.
          setSuccessData(values);
        }
        else {
          setFormError(`There was an error. Please try again later.`)
        }
      })
      .catch(error => {
        // Update UI to show Error MSG.
        console.log('submit error: ', error);
        setFormError(`${error}. Please try again later.`)
      });
  };

  return (
    <>
      <Helmet>
        <title>Partie | Contact</title>
        <html lang="en" />
        <meta name="description" content="Contact the Partie Team." />
      </Helmet>
      <PageLayout className="form-page" isLight>
        <Block form>
          <h2 className="partie-block__title">Contact Us</h2>
          {successData ? (
            <div className="success-message">
              <p>Thank you,
                your message has been
                submitted.</p>
              <TrackedLink type="btn btn--accent" to="https://app.partie.com" children="To Partie" />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <Form className="partie-form" id="partie-form">
                <DropDown name="subject" label="What can we help you with?" options={INQUIRY_OPTS} />
                <TextField name="name" label="Your name" type="text" />
                <TextField name="email" label="Your email" type="email" />
                <TextArea name="comments" label="Description/Comments" rows="12" maxLength="2000" />
                <div className="partie-form__field-container">
                  <button className="btn btn--accent" type="submit">
                    Submit
                  </button>
                  {formError && (
                    <span className="partie-form__field-caption">{formError}</span>
                  )}
                </div>
              </Form>
            </Formik>
          )}
        </Block>
      </PageLayout>
    </>
  );
};

export default ContactPage;